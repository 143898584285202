import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/main.css'
import { useFavicon } from '@vueuse/core'
import { createI18n } from 'vue-i18n'
import enUS from './locales/en-US.json'
window.$ = window.jQuery = require('jquery');

const i18n = createI18n({
    locale: 'en-US',
    messages: {
        'en-US': enUS
    }
})


//Here ve replace ICO with PNG
const icon = useFavicon()
if (process.env.VUE_APP_BUILD_TARGET_NAME === 'yeshiva') {
    icon.value = 'yeshiva_favicon.png' // change current icon
} else {
    icon.value = 'shmuzy_favicon.png' // change current icon
}

createApp(App).use(i18n).use(router).use(store).mount('#app')
